import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faShieldAlt } from "@fortawesome/free-solid-svg-icons"
import Layout from "../../../components/Layout";
import FormSB from "../../../components/Form";
import BlogNews from "../../../components/BlogNews";

export default class Verifique extends Component {
    state = {
        data: [],
    }

    componentDidMount() {
        document.title = "Site Blindado - Verifique";
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render() {
        const { data } = this.state;

        return (
            <Layout>
                <div className={`section-height-100-center bg-dark-green ${(data.status === 'error' ? 'bg-dark-red' : '') || (data.status === 'ok' ? 'bg-dark-blue' : '')}`}>
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <h1 className="font-black text-center">Verifique se um site é blindado</h1>
                                <h6 className="text-center">Preencha o formulário abaixo com o endereço do site que deseja pesquisar e verifique se ele está seguro contra vulnerabilidades.</h6>
                                <FormSB type='form-verify' parentCallback={this.callbackFunction} />
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            {data.status === 'ok' &&
                                <Card className="card-verify card-verify-active mt-4">
                                    <Row>
                                        <Col xs={2} className="icon d-flex justify-content-center align-items-center">
                                            <FontAwesomeIcon icon={faShieldAlt} />
                                        </Col>
                                        <Col xs={10} className="p-4">
                                            <h5 className="m-0">O site {data.url_inputed} <strong>é blindado contra invasores.</strong></h5>
                                        </Col>
                                    </Row>
                                </Card>
                            }
                            {data.status === 'error' &&
                                <Card className="card-verify card-verify-inactive mt-4">
                                    <Row>
                                        <Col xs={2} className="icon d-flex justify-content-center align-items-center">
                                            <FontAwesomeIcon icon={faExclamationTriangle} />
                                        </Col>
                                        <Col xs={10} className="p-4">
                                            <h5 className="m-0">
                                                {data.message !== undefined ?
                                                    data.message
                                                    :
                                                    <>O site <strong>não consta na lista de sites blindados</strong></>
                                                }
                                            </h5>
                                        </Col>
                                    </Row>
                                </Card>
                            }
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row>
                            <BlogNews />
                        </Row>
                        <Row className="padding-row">
                            <Col xs={12} md={12}>
                                <FormSB color="white" type="form-client" source="Página de Verifique" parentCallback={this.callbackFunction} title="Quero Ser Blindado" description={<>Preencha o formulário a seguir para que nossa equipe possa entrar em contato com você e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}